import { Box, Pagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CardSearchDetails } from "../../../../interfaces/CardSearch";
import { scrollToTop } from "../../../../utils/navigation/scrollPage";
import { createDisplayItems } from "../../../../utils/pagination/paginationUtils";
import MainCard from "../MainCard/MainCard";

interface MainPaginationProps {
  items: CardSearchDetails[];
  itemsPerPage: number;
}

const MainPagination = ({ items, itemsPerPage }: MainPaginationProps) => {
  const [page, setPage] = useState(1);
  const [displayItems, setDisplayItems] = useState<CardSearchDetails[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const assignItems = createDisplayItems(items, itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setDisplayItems(assignItems(value));
    scrollToTop();
  };

  useEffect(() => {
    setTotalPages(Math.ceil(items.length / itemsPerPage));
    setDisplayItems(assignItems(page));
  }, []);

  return (
    <div aria-labelledby="cardResultHeader">
      <Typography
        id="cardResultHeader"
        sx={{ fontSize: "1.5rem", marginTop: "1rem" }}
        variant="h2"
      >
        Card Results
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {displayItems.map((card: CardSearchDetails) => (
          <MainCard key={`${card.MoonpigProductNo}`} card={card} />
        ))}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: "25px" }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          shape="rounded"
        />
      </Box>
    </div>
  );
};

export default MainPagination;
