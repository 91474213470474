const FRONTEND_ROUTES = {
  HOME: "/",
  PRODUCT_DETAILS: "/product/:id",
  CART: "/cart",
};

export default FRONTEND_ROUTES;

export const getProductDetailsUrl = (id: string): string => {
  return FRONTEND_ROUTES.PRODUCT_DETAILS.replace(":id", id);
};
