import { CardMedia, Grid } from "@mui/material";

interface ProductDetailsLeftSideProps {
  altName: string;
  imageUrl: string;
}

const ProductDetailsLeftSide: React.FC<ProductDetailsLeftSideProps> = ({
  altName,
  imageUrl,
}) => (
  <Grid item xs={12} md={6}>
    <CardMedia
      component="img"
      alt={altName}
      height="100%"
      image={imageUrl}
      sx={{ maxHeight: "600px" }}
    />
  </Grid>
);
export default ProductDetailsLeftSide;
