import { Box, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { RootState } from "../../store";
import { removeFromCart } from "../../store/cart/cartSlice";

const CartPage: React.FC = () => {
  const cart = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch();
  return (
    <Box>
      <Typography variant="h4">Cart</Typography>
      {cart.cartItems.map((item) => (
        <Box key={item.CartId} sx={{ display: "flex", margin: "5px" }}>
          <Typography sx={{ flexGrow: "1" }} variant="h6">
            {item.Description}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h6">
              {item.Size.Currency}
              {item.Size.Price}
            </Typography>
            <IconButton
              data-testid="cart-item-remove-btn"
              onClick={() => dispatch(removeFromCart(item.CartId))}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default CartPage;
