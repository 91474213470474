import { Box, Card, CardContent, Typography } from "@mui/material";
import { CardSizeItem } from "../../../interfaces/CardDetails";

interface ProductDetailsCardSizeProps {
  cardSize: CardSizeItem[];
  setCardSize: (cardSize: CardSizeItem[]) => void;
}

const ProductDetailsCardSize: React.FC<ProductDetailsCardSizeProps> = ({
  cardSize,
  setCardSize,
}) => (
  <Box sx={{ flexGrow: "1" }}>
    {cardSize.map((size) => (
      <Card
        data-testid={`card-size-${size.Id}`}
        key={size.Id}
        style={{ border: size.IsSelected ? "1px solid red" : "" }}
        variant="outlined"
        sx={{ my: "10px", cursor: "pointer" }}
        onClick={() => {
          let updatedSize = cardSize.map((s) => ({
            ...s,
            IsSelected: false,
          }));
          updatedSize = updatedSize.map((s) => {
            if (s.Id === size.Id) {
              return { ...s, IsSelected: true };
            }
            return s;
          });
          setCardSize(updatedSize);
        }}
      >
        <CardContent sx={{ display: "flex" }}>
          <Typography sx={{ flexGrow: "1" }} variant="body2">
            {size.DisplayName}
          </Typography>
          <Typography variant="body2">
            {size.Currency}
            {size.Price}
          </Typography>
        </CardContent>
      </Card>
    ))}
  </Box>
);

export default ProductDetailsCardSize;
