import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FRONTEND_ROUTES from "../../consts/frontendRoutes";

interface MainTitleProps {
  isMobile?: boolean;
}

const MainTitle: React.FC<MainTitleProps> = ({ isMobile }) => (
  <Link to={FRONTEND_ROUTES.HOME}>
    <Typography
      variant="h2"
      noWrap
      sx={{
        mr: 2,
        fontSize: isMobile ? "1.3rem" : "2rem",
        display: {
          xs: `${isMobile ? "flex" : "none"}`,
          md: `${isMobile ? "none" : "flex"}`,
        },
        fontFamily: "monospace",
        fontWeight: 700,
        letterSpacing: ".3rem",
        color: "white",
        textDecoration: "none",
      }}
    >
      Moonpig Demo
    </Typography>
  </Link>
);

const ResponsiveAppBar: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (route?: string) => () => {
    setAnchorElNav(null);
    if (route) {
      navigate(route);
    }
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <MainTitle />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu()}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu(FRONTEND_ROUTES.CART)}>
                <Typography>Cart</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <MainTitle isMobile />

          <Box
            sx={{
              flexGrow: 1,
              flexDirection: "row-reverse",
              display: { xs: "none", md: "flex" },
            }}
          >
            <Link to={FRONTEND_ROUTES.CART}>
              <Button
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                }}
              >
                Cart
              </Button>
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <>
      <ResponsiveAppBar />
      <Box component="main" sx={{ p: 3 }}>
        {children}
      </Box>
    </>
  );
};

export default MainLayout;
