import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CardSearch from "../../interfaces/CardSearch";
import { getSearchCards } from "../../utils/ajax/fetchData";
import MainPagination from "./components/MainPagination/MainPagination";

const HomePage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState<CardSearch | null>(null);
  useEffect(() => {
    async function getSearchData() {
      setIsLoading(true);
      const data = await getSearchCards();
      setSearchData(data);
      setIsLoading(false);
    }
    getSearchData();
  }, []);

  if (isLoading || !searchData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <header>
        <Typography sx={{ fontSize: "2.5rem" }} variant="h1">
          Card Home
        </Typography>
      </header>
      <MainPagination items={searchData.Products} itemsPerPage={25} />
    </>
  );
};

export default HomePage;
