import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { getProductDetailsUrl } from "../../../../consts/frontendRoutes";
import { CardSearchDetails } from "../../../../interfaces/CardSearch";

interface MainCardProps {
  card: CardSearchDetails;
}

const MainCard: React.FC<MainCardProps> = ({ card }) => {
  return (
    <Box
      sx={{
        width: 275,
        margin: "10px",
        border: "1px solid #eadfdf;",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardMedia
        component="img"
        alt={card.ProductImage.Link.Title}
        height="194"
        image={card.ProductImage.Link.Href}
      />
      <CardContent sx={{ flexGrow: "1" }}>
        <Typography gutterBottom variant="subtitle1" component="h3">
          {card.Title}
        </Typography>
      </CardContent>
      <CardActions>
        <Link to={getProductDetailsUrl(card.MoonpigProductNo)}>
          <Button data-testid="mc-component-btn" size="small">
            Check it out
          </Button>
        </Link>
      </CardActions>
    </Box>
  );
};

export default MainCard;
