import CardDetails from "../../interfaces/CardDetails";

export const SEARCH_CARD_URL =
  "https://moonpig.github.io/tech-test-frontend/search.json";

export const CARD_DETAILS_URL =
  "https://moonpig.github.io/tech-test-frontend/product/:id.json";

export const getSearchCards = async (): Promise<any> => {
  const response = await fetch(SEARCH_CARD_URL);
  return response.json();
};

export const getCardDetails = async (id: string): Promise<CardDetails> => {
  const response = await fetch(CARD_DETAILS_URL.replace(":id", id));
  return response.json();
};
