import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CardDetails, { CardSizeItem } from "../../interfaces/CardDetails";
import { addToCart } from "../../store/cart/cartSlice";
import { getCardDetails } from "../../utils/ajax/fetchData";
import ProductDetailsCardSize from "./components/ProductDetailsCardSize";
import ProductDetailsLeftSide from "./components/ProductDetailsLeftSide";

const ProductDetails: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [card, setCard] = useState<CardDetails | null>(null);
  const [cardSize, setCardSize] = useState<CardSizeItem[]>([]);
  const dispatch = useDispatch();

  const id = useParams<{ id: string }>().id;

  useEffect(() => {
    async function getCardData(id: string) {
      try {
        setIsLoading(true);
        const data = await getCardDetails(id);
        setCard(data);

        let sizeItems = data.AvailableSizes.map((s) => {
          const isSelected = s.Id === data.Size.Id;
          return {
            ...s,
            IsSelected: isSelected,
          };
        });

        const noItemSelected = sizeItems.every((s) => !s.IsSelected);

        if (noItemSelected) {
          sizeItems = [...sizeItems, { ...data.Size, IsSelected: true }];
        }

        setCardSize(sizeItems);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
    getCardData(id ?? "");
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!card) {
    return <div>Card not found</div>;
  }

  const handleClick = () => {
    const updatedCard = {
      ...card,
      Size: cardSize.find((item) => item.IsSelected === true) ?? card.Size,
      CartId: uuidv4(),
    };

    dispatch(addToCart(updatedCard));
  };

  return (
    <Grid container spacing={2}>
      <ProductDetailsLeftSide
        altName={card.Name}
        imageUrl={card.ImageUrls[0].ImageUrl}
      />
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <header>
          <Typography variant="h1" sx={{ fontSize: "1.8rem" }}>
            {card.Title}
          </Typography>
        </header>
        <ProductDetailsCardSize cardSize={cardSize} setCardSize={setCardSize} />
        <Button onClick={handleClick} variant="outlined">
          Buy Me
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProductDetails;
