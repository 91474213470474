import React from "react";
import ReactDOM from "react-dom/client";
import { store } from "./store";
import { Provider } from "react-redux";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FRONTEND_ROUTES from "./consts/frontendRoutes";
import HomePage from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import CartPage from "./pages/Cart";
import MainLayout from "./pages/_layout/MainLayout";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <Routes>
          <Route
            path={FRONTEND_ROUTES.HOME}
            element={
              <MainLayout>
                <HomePage />
              </MainLayout>
            }
          />
          <Route
            path={FRONTEND_ROUTES.PRODUCT_DETAILS}
            element={
              <MainLayout>
                <ProductDetails />
              </MainLayout>
            }
          />
          <Route
            path={FRONTEND_ROUTES.CART}
            element={
              <MainLayout>
                <CartPage />
              </MainLayout>
            }
          />
        </Routes>
      </Provider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
